<script lang="ts" setup>
import { useTgCustomer } from '@/hooks/useTgCustomer'

const tgLink = useTgCustomer()
</script>

<template>
  <div>
    <a class="service-wrapper block" :href="tgLink" target="_blank" :title="$t('AdXmjYoBblaVXYOkEC_jF')" data-test-id="xWac">
      <i class="icon i-local:telegram block text-1.1em" />
    </a>
  </div>
</template>

<style scoped>
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 1.5px solid #8098bf;
    border-radius: 50%;
  }
</style>
